#title {
  margin: 0 50px 0 10px;
}

a {
  margin: 0 15px 0 15px;
  color: white;
  text-decoration: none;
}

a.active {
  color: red;
}
